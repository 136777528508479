// components/Home.js
import { Heading } from '@aws-amplify/ui-react';
import { CircleLoader } from 'react-spinners';
import { Store } from 'react-notifications-component';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import { CardContent, CardMedia, CardActionArea } from '@mui/material';
import TextField from '@mui/material/TextField';
import pyrroimages1 from '../../assets/img/pyrroimages2-01.png';
import pyrroimages2 from '../../assets/img/pyrroimages2-02.png';
import pyrroimages3 from '../../assets/img/pyrroimages2-03.png';
import dlabulamanzi from '../../assets/img/dlabulamanzi-logo.png';
import bluef from '../../assets/img/bluef.png';
import Stack from '@mui/material/Stack';
import './home.css';
import { HashLink as Link } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  width: '100%',
  margin: "auto"
}));

export function Home() {
  const [isLoaded, setIsLoaded] = useState(true)
  const [notificationID, setNotificationID] = useState("")

  function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  // useEffect(() => {
  //   const getData = async () => {

  //     // setNotificationID(Store.addNotification({
  //     //   title: "Wonderful!",
  //     //   message: "teodosii@react-notifications-component",
  //     //   type: "warning",
  //     //   insert: "top",
  //     //   container: "top-left",
  //     //   animationIn: ["animate__animated", "animate__fadeIn"],
  //     //   animationOut: ["animate__animated", "animate__fadeOut"],
  //     //   dismiss: {
  //     //     duration: 2000,
  //     //     onScreen: true
  //     //   }
  //     // }));

  //     // Store.removeNotification(notificationID)
  //     // await delay(1000);
  //     setIsLoaded(true)

  //   }
  //   getData()
  // }, [])

  const { t } = useTranslation();

  return (
    <>
      {isLoaded ? (
        <>
          {/* <Heading level={3}>
              Home...
            </Heading> */}

          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Item className="top-section">
                  <section id="landing">
                    <Typography variant="h2"
                      sx={{
                        background: "transparent",
                        color: "#fff", textAlign: {xs: "center", md: "left"},
                        marginLeft: "3%", paddingTop: "13%",   fontSize: { xs: "2rem", sm: "4rem", md: "5rem" },
                        fontFamily: "Orbitron", fontWeight: "bold"
                      }}>{t('We do the tech')} </Typography>

                    <Typography variant="body1"
                      sx={{
                        fontSize: "1.2rem", color: "#fff",
                        textAlign: {xs: "center", md: "left"}, marginLeft: "4%",
                        marginTop: {xs: "10%", sm: "3%"}
                      }}> {t('So you can focus on what you do best.')} </Typography>

                    <Button variant="outlined"
                     component={Link} to="/home#services"
                      sx={{
                        padding: "1.5vh 1vw", color: "#fff", fontSize: "1.1rem", borderRadius: "0.5%",
                        width: {xs: "60%", sm: "20%", md: "20%", lg: "10%"},
                        whiteSpace: "nowrap",
                        backgroundColor: "transparent", marginTop: {xs: "6%", sm: "2.5%", lg: "2%"}, marginLeft: {xs: "auto", md: "4%", lg: "4%"},
                        margin: {xs: "2vh auto", md: "initial"},
                        border: "0.02rem #009688 solid", opacity: "0.60", display: "flex", justifyContent: "center", '&:hover': {
                          border: "0.15rem #2A3439 solid",
                          color: "#009688"
                        }
                      }}> {t('Learn more')} </Button>
                  </section>
                </Item>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Item className='aboutUs' sx={{ backgroundColor: "#0e1316", boxShadow: "none", border: "none", display: "flex", justifyContent: "center" }}>
                  <section id="about" className="about-section">
                    <Typography className="aboutUsText" variant="h5" sx={{  fontFamily: "Roboto Condensed", fontSize: "1.7rem", mb: 5, mt: 18 }}> {t('ABOUT US')} </Typography>
                    <Typography variant="body1" className='aboutUsText' sx={{ fontSize: "1.2rem", mb: 3 }}> {t('what we do:')} </Typography>

                    <Stack direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }} sx={{ display: "flex", justifyContent: "center" }}>

                      <Card id="infra" sx={{ maxWidth: 380, backgroundColor: "#354A54", borderRadius: "0", margin: "4% 1.5vw", boxShadow: "0 0 1vw rgba(0,0,0,0.4)" }}>
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height="345"
                            image={pyrroimages1}
                            alt="Infrastructure and Management"
                            sx={{ backgroundColor: "#F5F5F5" }}
                          />

                          <CardContent sx={{ backgroundColor: "#354A54" }}>
                            <Typography gutterBottom variant="h6" component="div" sx={{ fontFamily: "Roboto", color: "#fff", mt: 2, fontSize: "1rem", fontWeight: "bold" }}>
                             {t('INFRASTRUCTURE AND MANAGEMENT')}
                            </Typography>

                            <Typography variant="h5" sx={{ fontSize: "1.1rem", color: "#fff", mt: "6%", fontFamily: "Poppins" }}>
                             {t('You are good at what you do.')}
                            </Typography>

                            <Typography variant="body1" sx={{ color: "#fff", lineHeight: "1.6", marginTop: "6%", textAlign: "center" }}>
                             {t('Let us take care of the tech. We will cover your infrastructure and domain set up, and manage it for you, so you can get back to what you do best.')}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>

                      <Card id="soft" sx={{ maxWidth: 380, backgroundColor: "#354A54", borderRadius: "0", margin: "4% 1.5vw", boxShadow: "0 0 1vw rgba(0,0,0,0.4)" }}>
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height="345"
                            image={pyrroimages2}
                            alt="Infrastructure and Management"
                            sx={{ backgroundColor: "#F5F5F5" }}
                          />

                          <CardContent sx={{ backgroundColor: "#354A54" }}>
                            <Typography gutterBottom variant="h6" component="div" sx={{ fontFamily: "Roboto", color: "#fff", mt: 2, fontSize: "1rem", fontWeight: "bold" }}>
                             {t('CUSTOMER DRIVEN SOFTWARE')}
                            </Typography>

                            <Typography variant="h5" sx={{ fontSize: "1.1rem", color: "#fff", mt: "6%", fontFamily: "Poppins" }}>
                             {t('Software that works for you.')}
                            </Typography>

                            <Typography variant="body1" sx={{ color: "#fff", lineHeight: "1.6", marginTop: "6%", textAlign: "center" }}>
                              {t('Instead of forcing your needs to fit pre-existing tools, we will build a one-of-a-kind solution around your requirements.')}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>

                      <Card id="custom" sx={{ maxWidth: 380, backgroundColor: "#354A54", borderRadius: "0", margin: "4% 1.5vw", boxShadow: "0 0 1vw rgba(0,0,0,0.4)" }}>
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height="345"
                            image={pyrroimages3}
                            alt="Infrastructure and Management"
                            sx={{ backgroundColor: "#F5F5F5" }}
                          />

                          <CardContent sx={{ backgroundColor: "#354A54" }}>
                            <Typography gutterBottom variant="h6" component="div" sx={{ fontFamily: "Roboto", color: "#fff", mt: 2, fontSize: "1rem", fontWeight: "bold" }}>
                                {t('CUSTOM SOLUTIONS')}
                            </Typography>

                            <Typography variant="h5" sx={{ fontSize: "1.1rem", color: "#fff", mt: "6%", fontFamily: "Poppins" }}>
                              {t('Your work is unique.')}
                            </Typography>

                            <Typography variant="body1" sx={{ color: "#fff", lineHeight: "1.6", marginTop: "6%", textAlign: "center" }}>
                              {t('So are your needs. Complex problems need custom solutions. Outline your needs to us and we will develop a digital solution customised to you.')}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Stack>
                  </section>
                </Item>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Item className='servicesSection' sx={{  boxShadow: "none", border: "none", display: "flex", justifyContent: "center" }}>
                  <section id="services" className="services-section">
                    <Typography variant="h6" sx={{ color: "#fff", fontFamily: "Roboto Condensed", fontSize: "1.5rem", mt: 23, mb: 4 }}> {t('WHAT WE OFFER')} </Typography>
                    <Typography variant="subtitle1" sx={{ fontSize: "1.2rem", color: "#fff", mb: 12 }}> {t('at a glance:')} </Typography>

                    <Stack direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }} sx={{ display: "flex", justifyContent: "center" }}>

                      <Card className='servicesSection' sx={{ maxWidth: 345, margin: "5% 2%", color: "#fff", boxShadow: "none", border: "none" }}>
                        <CardActionArea>
                          <CardMedia>
                            <i className="fa fa-cogs fa-5x" aria-hidden="true" style={{ color: "#fff" }}></i>
                          </CardMedia>

                          <CardContent>
                            <Typography gutterBottom variant="h6" component="div">
                              {t('Custom Set-Up')}
                            </Typography>

                            <Typography variant="subtitle1">
                              {t('Serverless infrastructure scaled to your needs. Domain registration and management.')}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>

                      <Card className='servicesSection' sx={{ maxWidth: 345, margin: "5% 2%", color: "#fff", boxShadow: "none", border: "none" }}>
                        <CardActionArea>
                          <CardMedia>
                            <i className="fa fa-code fa-5x" aria-hidden="true" style={{ color: "#fff" }}></i>
                          </CardMedia>

                          <CardContent>
                            <Typography gutterBottom variant="h6" component="div">
                              {t('Custom Software')}
                            </Typography>

                            <Typography variant="subtitle1">
                              {t('Providing practical digital solutions for every day business problems.')}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>

                      <Card className='servicesSection' sx={{ maxWidth: 345, margin: "5% 2%", color: "#fff",  boxShadow: "none", border: "none" }}>
                        <CardActionArea>
                          <CardMedia>
                            <i className="fa fa-pencil fa-5x" aria-hidden="true" style={{ color: "#fff" }}></i>
                          </CardMedia>

                          <CardContent>
                            <Typography gutterBottom variant="h6" component="div">
                              {t('Design')}
                            </Typography>

                            <Typography variant="subtitle1">
                              {t('Our team works in collaboration with yours to bring your vision to fruition.')}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>

                      <Card className='servicesSection' sx={{ maxWidth: 345, margin: "5% 2%", color: "#fff", boxShadow: "none", border: "none" }}>
                        <CardActionArea>
                          <CardMedia>
                            <i className="fa fa-comments-o fa-5x" aria-hidden="true" style={{ color: "#fff" }}></i>
                          </CardMedia>
                          <CardContent>
                            <Typography gutterBottom variant="h6" component="div">
                              {t('Support')}
                            </Typography>

                            <Typography variant="subtitle1">
                              {t('Ticket resolution after handover and dedicated customer support.')}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Stack>
                  </section>
                </Item>
              </Grid>


              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Item className='accomplishments' sx={{ boxShadow: "none", minHeight: "30vh", display: "flex", justifyContent: "center" }}>
                  <section className="stats-section">
                    <Stack direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }} sx={{ display: "flex", justifyContent: "center", verticalAlign: "middle" }}>

                      <Card className="accomplishmentsCard" sx={{ maxWidth: 345, margin: "3% 4%", border: "none", boxShadow: "none", verticalAlign: "middle" }}>
                        <CardActionArea>
                          <CardContent className="accomplishmentsCardText">
                            <Typography variant="h6" fontSize={"2.2rem"}> {t('14+')} </Typography>
                            <Typography variant="h6"> {t('Portfolios Managed')} </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>

                      <Card className="accomplishmentsCard" sx={{ maxWidth: 345, margin: "3% 4%", border: "none", boxShadow: "none" }}>
                        <CardActionArea>
                          <CardContent className="accomplishmentsCardText">
                            <Typography variant="h6" fontSize={"2.2rem"}> {t('20+')} </Typography>
                            <Typography variant="h6"> {t('Projects Done')} </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>

                      <Card className="accomplishmentsCard" sx={{ maxWidth: 345, margin: "3% 4%", border: "none", boxShadow: "none" }}>
                        <CardActionArea>
                          <CardContent className="accomplishmentsCardText">
                            <Typography variant="h6" fontSize={"2.2rem"}> {t('20+ ')}</Typography>
                            <Typography variant="h6"> {t('Happy Clients')} </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Stack>
                  </section>
                </Item>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Item className="testimonialSections" sx={{ display: "flex", justifyContent: "center", boxShadow: "none" }}>
                  <section className="testimonial-section">
                    <Stack direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }} sx={{ display: "flex", justifyContent: "center" }}>

                      <Card sx={{ maxWidth: 345, margin: "3vw", borderRadius: "0%", boxShadow: "0 0 1vw rgba(0,0,0,0.4)" }}>
                        <CardContent
                          sx={{ height: 180, width: "100%", margin: "auto auto", backgroundColor: "#009688", border: "#009688 0.2rem solid", fontVariant: "small-caps" }}
                        >
                          <img src={dlabulamanzi} style={{ height: "80%", backgroundColor: "#e6f3f9", borderRadius: "50%", width: "40%", backgroundSize: "contain", backgroundRepeat: "no-repeat", filter: "grayscale(1)" }} />
                          <Typography gutterBottom variant="h6" color="#fff" component="div">
                          {t('  Dlabulamanzi')}
                          </Typography>
                        </CardContent>

                        <CardContent sx={{ backgroundColor: "#fff" }}>
                          <Typography gutterBottom variant="h6" component="div" sx={{ padding: "3vh", paddingRight: "0px", textAlign: "left", width: "100%", fontWeight: "normal" }}>
                            {t('We required something that had not been done before, and they exceeded expectations.')}
                            <i className="fa fa-quote-right" aria-hidden="true"></i>
                          </Typography>
                          <Typography gutterBottom variant="subtitle1" component="div" mt={2} sx={{ display: "flex", justifyContent: {xs: "center", sm: "flex-end"}, fontVariant: "small-caps" }}>
                            {t('David Buthelezi')}
                          </Typography>
                        </CardContent>
                      </Card>

                      <Card sx={{ maxWidth: 345, backgroundColor: "#fff", margin: "3vw", borderRadius: "0%", boxShadow: "0 0 1vw rgba(0,0,0,0.4)" }}>
                        <CardContent
                          sx={{ height: 180, width: "100%", margin: "auto auto", backgroundColor: "#009688", border: "#009688 0.2rem solid", fontVariant: "small-caps" }}>
                          <img src={bluef} style={{ height: "80%", backgroundColor: "#e6f3f9", borderRadius: "50%", width: "40%", backgroundSize: "contain", backgroundRepeat: "no-repeat", filter: "grayscale(1)" }} />

                          <Typography gutterBottom variant="h6" color="#fff" component="div">
                            {t('Harsha Group')}
                          </Typography>
                        </CardContent>

                        <CardContent sx={{ backgroundColor: "#fff" }}>
                          <Typography gutterBottom variant="h6" component="div" sx={{ padding: "3vh", textAlign: "left", width: "100%", fontWeight: "normal" }}>
                             {t('Quick turnaround time. They were flexible and matched our needs.')}
                            <i className="fa fa-quote-right" aria-hidden="true"></i>
                          </Typography>

                          <Typography gutterBottom variant="subtitle1" component="div" mt={2} sx={{  display: "flex", justifyContent: {xs: "center", sm: "flex-end"}, fontVariant: "small-caps" }}>
                            {t('Harry Komape')}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Stack>
                  </section>
                </Item>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Item sx={{ backgroundColor: "#2A3439", paddingBottom: 22 }}>
                  <Typography id="support" variant='h5' color={'#fff'} mt={10} mb={3} fontFamily={"Roboto condensed"} fontWeight={"bold"} > {t('SUPPORT PLANS')} </Typography>
                  <Typography variant='h6' fontWeight={"normal"} color={'#fff'} mt={4} mb={10}> {t('Choose a support plan that fits your needs.')} </Typography>
                  <section className="support-section">
                    <Stack direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }} sx={{}}>

                      <Card id="basic" sx={{ maxWidth: 345, margin: "4% 0.52vw" }}>
                        <div className="support-title">
                          <Typography variant="h6"> {t('Basic')} </Typography>
                        </div>

                        <CardActionArea>
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                              <ul  style={{ paddingLeft: "0px"}}>
                                <li style={{ padding: "1.04vw", borderBottom: "0.13vh solid #ddd", listStyleType: "none" }}>
                                  <b>{t('Automated')}</b> {t('Infrastructure Support')}
                                </li>

                                <li style={{ padding: "1.04vw", borderBottom: "0.13vh solid #ddd", listStyleType: "none" }}>
                                  <b>{t('Email/Chat')}</b> {t('Support Consultation')}
                                </li>

                                <li style={{ padding: "1.04vw", borderBottom: "0.13vh solid #ddd", listStyleType: "none" }}>
                                  <b>{t('On Demand')}</b>  {t('Reporting')}
                                </li>

                                <li style={{ padding: "1.04vw", borderBottom: "0.13vh solid #ddd", listStyleType: "none" }}>
                                  {t('8-32')} <span style={{ opacity: 0.60 }}>{t('hours dedicated support a month')}</span>
                                </li>
                              </ul>
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>

                      <Card id="intermediate" sx={{ maxWidth: 345, margin: "4% 0.52vw" }}>
                        <div className="support-title">
                          <Typography variant="h6">{t('Intermediate')} </Typography>
                        </div>

                        <CardActionArea>
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                              <ul  style={{ paddingLeft: "0px"}}>
                                <li style={{ padding: "1.04vw", borderBottom: "0.13vh solid #ddd", listStyleType: "none" }}>
                                  <b>{t('Automated')}</b> {t('Infrastructure Support')}
                                </li>

                                <li style={{ padding: "1.04vw", borderBottom: "0.13vh solid #ddd", listStyleType: "none" }}>
                                  <b>{t('Email/Chat')}</b> {t('Support Consultation')}
                                </li>

                                <li style={{ padding: "1.04vw", borderBottom: "0.13vh solid #ddd", listStyleType: "none" }}>
                                  <b>{t('Automated')}</b> {t('Software Monitoring')}
                                </li>

                                <li style={{ padding: "1.04vw", borderBottom: "0.13vh solid #ddd", listStyleType: "none" }}>
                                  <b>{t('On Demand')}</b> {t('Ticket Reporting')}
                                </li>

                                <li style={{ padding: "1.04vw", borderBottom: "0.13vh solid #ddd", listStyleType: "none" }}>
                                  <b>{t('On Demand')}</b> {t('Reporting')}
                                </li>

                                <li style={{ padding: "1.04vw", borderBottom: "0.13vh solid #ddd", listStyleType: "none" }}>
                                  {t('24-96')} <span style={{ opacity: 0.60 }}>{t('hours dedicated support a month')}</span>
                                </li>
                              </ul>
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>

                      <Card id="specialised" sx={{ maxWidth: 345, margin: "4% 0.52vw" }}>
                        <div className="support-title" style={{ backgroundColor: "#009688" }}>
                          <Typography variant="h6"> {t('Specialised')} </Typography>
                        </div>
                        <CardActionArea>
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                              <ul  style={{ paddingLeft: "0px"}}>
                                <li style={{ padding: "1.04vw", borderBottom: "0.13vh solid #ddd", listStyleType: "none" }}>
                                  <b>{t('Automated')}</b> {t('Infrastructure Support')}
                                </li>

                                <li style={{ padding: "1.04vw", borderBottom: "0.13vh solid #ddd", listStyleType: "none" }}>
                                  <b>{t('Automated')}</b> {t('Software Monitoring')}
                                </li>

                                <li style={{ padding: "1.04vw", borderBottom: "0.13vh solid #ddd", listStyleType: "none" }}>
                                  <b>{t('On Demand')}</b> {t('Ticket Resolution')}
                                </li>

                                <li style={{ padding: "1.04vw", borderBottom: "0.13vh solid #ddd", listStyleType: "none" }}>
                                  <b>{t('On Demand')}</b> {t('Reporting')}
                                </li>

                                <li style={{ padding: "1.04vw", borderBottom: "0.13vh solid #ddd", listStyleType: "none" }}>
                                  <b>{t('Support')}</b> {t('Consultation')}
                                </li>

                                <li style={{ padding: "1.04vw", borderBottom: "0.13vh solid #ddd", listStyleType: "none" }}>
                                  <b>{t('Phone/Email')}</b> {t('Support')}
                                </li>

                                <li style={{ padding: "1.04vw", borderBottom: "0.13vh solid #ddd", listStyleType: "none" }}>
                                 {t('Custom ')}<span style={{ opacity: 0.60 }}>{t('hours dedicated support a month')}</span>
                                </li>
                              </ul>
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>

                      <Card id="professional" sx={{ maxWidth: 345, margin: "4% 0.52vw" }}>
                        <div className="support-title">
                          <Typography variant="h6"> {t('Professional')} </Typography>
                        </div>
                        <CardActionArea>
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                              <ul style={{ paddingLeft: "0px"}}>
                                <li style={{ padding: "1.04vw", borderBottom: "0.13vh solid #ddd", listStyleType: "none" }}>
                                  <b>{t('Automated')}</b> {t('Infrastructure Support')}
                                </li>

                                <li style={{ padding: "1.04vw", borderBottom: "0.13vh solid #ddd", listStyleType: "none" }}>
                                  <b>{t('Automated')}</b> {t('Software Monitoring')}
                                </li>

                                <li style={{ padding: "1.04vw", borderBottom: "0.13vh solid #ddd", listStyleType: "none" }}>
                                  <b>{t('On Demand')}</b> {t('Ticket Reporting')}
                                </li>

                                <li style={{ padding: "1.04vw", borderBottom: "0.13vh solid #ddd", listStyleType: "none" }}>
                                  <b>{t('24/7')}</b> {t('Support Consultation')}
                                </li>

                                <li style={{ padding: "1.04vw", borderBottom: "0.13vh solid #ddd", listStyleType: "none" }}>
                                  <b>{t('On Demand')}</b> {t('Reporting')}
                                </li>

                                <li style={{ padding: "1.04vw", borderBottom: "0.13vh solid #ddd", listStyleType: "none" }}>
                                  {t('40+ ')}<span style={{ opacity: 0.60 }}>{t('hours dedicated support a month')}</span>
                                </li>
                              </ul>
                            </Typography>

                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Stack>
                  </section>
                </Item>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Item className="contactSection" sx={{ display: "flex", justifyContent: "center", color: "#fff", boxShadow: "none", border: "none" }}>
                  <section id="contact" className="contact-section">
                    <Typography variant="h5" className="contactText" mt={14} mb={3}> {t('CONTACT')}</Typography>

                    <Typography variant="body1" className="contactText" mb={5}> {t('lets get in touch')} </Typography>

                    <Typography variant="h6" className="contactText"><i className="fa fa-map-marker fa-2x" aria-hidden="true" style={{ paddingRight: "1.62vw", marginBottom: "1.79vh" }}></i> {t('Kensington, Johannesburg')}</Typography>

                    <Typography variant="h6" className="contactText"><i className="fa fa-phone fa-2x" aria-hidden="true" style={{ paddingRight: "1.62vw", marginBottom: "1.79vh" }}></i> {t('Phone: +27 60 967 8568')}</Typography>

                    <Typography variant="h6" className="contactText"><i className="fa fa-envelope fa-2x" aria-hidden="true" style={{ paddingRight: "1.62vw", marginBottom: "5.13vh" }}></i> {t('Email: pyrrho.tech@pyrrhotech.com')}</Typography>


                    <Box
                      component="form"
                      sx={{
                        '& .MuiTextField-root': { m: 1, width: "25ch", color: "#fff", borderColor: "fff" },
                        display: "flex", justifyContent: "center", color: "#fff", marginTop: "4%"
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <Stack direction="column" sx={{ display: "flex", justifyContent: "center" }}>
                        <TextField className='ttk'
                          style={{ color: "gray", backgroundColor: "#fff", width: "51.95vw" }}
                          id="outlined-name"
                          placeholder="Name"
                        />
                        <TextField className='ttk'
                          style={{ color: "gray", backgroundColor: "#fff", width: "51.95vw" }}
                          id="outlined-email"
                          placeholder="Email"
                        />
                        <TextField className='ttk'
                          style={{ color: "gray", backgroundColor: "#fff", width: "51.95vw" }}
                          id="outlined-name"
                          placeholder="Subject"
                        />
                        <TextField className='ttk'
                          style={{ color: "gray", backgroundColor: "#fff", width: "51.95vw" }}
                          id="outlined-message"
                          placeholder="Message"
                        />
                      </Stack>
                    </Box>
                    <Button variant="outlined"
                      className="contactText button"
                      sx={{
                        padding: "1.65vh 2vw", fontSize: "1.125rem",
                        backgroundColor: "transparent", marginTop: "4.82vh", marginBottom: 15, marginLeft: "3.45vw",
                        border: "0.06vw #009688 solid", opacity: "0.60", borderRadius: "0.06vw", '&:hover': {
                          border: "0.15rem #2A3439 solid",
                          color: "#009688"
                        }
                      }}> {t('SEND MESSAGE')} </Button>
                  </section>
                </Item>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
          <div style={{ marginTop: '25vh' }}><CircleLoader color="#c3978f" size={100} /></div>

        </div>
      )
      }
    </>


  );
}
