// components/Home.js
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import './400.css';
import { Heading } from '@aws-amplify/ui-react';


export function NotFoundPage() {
  return (
    <>
   <Container sx={{ backgroundColor: "fff" }}>
        <Heading level={3}>
          <Typography className='notf' variant="h1"  sx={{fontSize: {xs: 75, sm: 100, md: 200, lg: 332, xl: 300}}} mt={20}>
            404
          </Typography>
        </Heading>

        <Typography variant="h3" color="#747F85">This page cannot be found.</Typography>
      </Container>
  </>
  );
}