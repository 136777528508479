// import { ContactUs } from "./pages/contact/contactUs";
import { useTranslation } from "react-i18next";
import { FAQ } from "./pages/faq/faq";
import { Home } from "./pages/home/home";

import { Protected } from './pages/userHome/userHome';

export interface AppRoute{
    Navigation: string,
    DisplayName: string,
    Protected: boolean,
    Anchor: string,
    Component: () => JSX.Element,
    NavBarVisibile: boolean
}

export const CustomRoutes : AppRoute[] = [
    {
        DisplayName: 'Home',
        Navigation: '/home',
        Protected: false,
        Anchor: '',
        Component: Home,
        NavBarVisibile: true
    },
    {
        DisplayName: 'About',
        Navigation: '/home',
        Protected: false,
        Anchor: '#about',
        Component: Home,
        NavBarVisibile: true
    },
    {
        DisplayName: 'Services',
        Navigation: '/home',
        Protected: false,
        Anchor: '#services',
        Component: Home,
        NavBarVisibile: true
    },
    {
        DisplayName: 'Contact',
        Navigation: '/home',
        Protected: false,
        Anchor: '#contact',
        Component: Home,
        NavBarVisibile: true
    },
    {
        DisplayName: 'FAQ',
        Navigation: '/faq',
        Protected: false,
        Anchor: '',
        Component: FAQ,
        NavBarVisibile: true
    },
    
]

// export const SettingRoutes : AppRoute[] = [
//     {
//         DisplayName: 'Profile',
//         Navigation: '/profile',
//         Protected: true,
//         Component: Protected,
//         NavBarVisibile: true
//     },
// ]