// components/Home.js
import { Heading } from '@aws-amplify/ui-react';
import { CircleLoader } from 'react-spinners';
import { Store } from 'react-notifications-component';
import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import './faq.css';
import LanguageSelector from '../../components/LanguageSelector';
import Content from '../../components/Content';
import Sidebar from '../../components/Sidebar';
import { useTranslation } from 'react-i18next';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#0e1316',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export function FAQ() {
  const [isLoaded, setIsLoaded] = useState(false)
  const [notificationID, setNotificationID] = useState("")

  function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
    const getData = async () => {

      // setNotificationID(Store.addNotification({
      //   title: "Wonderful!",
      //   message: "teodosii@react-notifications-component",
      //   type: "warning",
      //   insert: "top",
      //   container: "top-left",
      //   animationIn: ["animate__animated", "animate__fadeIn"],
      //   animationOut: ["animate__animated", "animate__fadeOut"],
      //   dismiss: {
      //     duration: 2000,
      //     onScreen: true
      //   }
      // }));

      // Store.removeNotification(notificationID)
      // await delay(1000);
      setIsLoaded(true)

    }
    getData()
  }, [])

  const { t } = useTranslation();

  return (
    <>
      {isLoaded ? (
        <>
          <Heading className="faqText" id="faq" level={3} fontSize={"1.5rem"} fontFamily={"Roboto Condensed"} marginTop={"20%"}>
            {t('FAQ...')}
          </Heading>

          <Typography className="faqText" fontSize={"1.3rem"}>{t('what you need to know')}</Typography>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Item className="faqCard" sx={{ border: "none", boxShadow: "none" }}>
              <Card className="faqCard" sx={{ margin: "0.009vw 2.06vh", color: "#fff", boxShadow: "none", border: "none" }}>
                <CardActionArea className="faqCard">
                  <CardContent>
                    <Typography gutterBottom variant="h6" className="faqText" component="div" marginTop={"7%"} fontSize={"1.6rem"} fontFamily={"Poppins"} fontWeight={"normal"} marginBottom={"3%"}>
                     {t('Should my company use Pyrrho Tech or form our own IT division?')}
                    </Typography>

                    <Typography variant="subtitle1" className="faqText" textAlign={"center"}>
                      {t('This depends on the scale of your IT needs. For smaller companies with more specific needs, using services like those offered by Pyrrho Tech is more cost effective than having an entire department on the payroll. For larger companies, hiring Pyrrho Tech to help aid your existing or growing department helps you get set up faster and align your tech department to DevOps thinking and processes with the guide of skilled professionals.')}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Item>
          </Grid>


          <Grid container spacing={2} mt={6}>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Item className="faqCard" sx={{ boxShadow: "none", border: "none" }}>
                <Card className="faqCard" sx={{ margin: "0.009vw 2.06vh", color: "#fff", boxShadow: "none", border: "none" }}>
                  <CardActionArea className="faqCard">
                    <CardContent>
                      <Typography gutterBottom variant="h6" className="faqText" component="div" marginTop={"7%"} fontSize={"1.6rem"} fontFamily={"Poppins"} fontWeight={"normal"} marginBottom={"3%"}>
                        {t('Why use Pyrrho Tech?')}
                      </Typography>

                      <Typography className="faqText" variant="subtitle1">
                       {t('Pyrrho was started by professionals with a combined twenty years of experience in technology. We have experience with large scale projects and smaller pieces of work, and we put the same dedication into both.')}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Item>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Item className="faqCard" sx={{ boxShadow: "none", border: "none" }}>
                <Card className="faqCard" sx={{ margin: "0.009vw 2.06vh", boxShadow: "none", border: "none", '&hover': {} }}>
                  <CardActionArea className="faqCard">
                    <CardContent>
                      <Typography gutterBottom variant="h6" className="faqText" component="div" marginTop={"7%"} fontSize={"1.4rem"} fontFamily={"Poppins"} fontWeight={"normal"} marginBottom={"3%"}>
                        {t('Why don\'t you show prices on the website?')}
                      </Typography>

                      <Typography variant="subtitle1" className="faqText">
                       {t('Everyone\'s tech needs are unique. Boilerplate pricing leaves too much of a margin that may end up costing you more. We scale our prices to cover your specific requirements so you never have to pay for more than you need.')}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Item>
            </Grid>
          </Grid>


          <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ backgroundColor: "#2A3439", marginTop: "10%", paddingBottom: "15%" }} id="privacy">
            <Typography  fontSize={"1.5rem"} color={"#fff"} paddingTop={"10%"} fontFamily={"Roboto Condensed"} fontWeight={"bold"}> {t('PRIVACY')} </Typography>
            <Typography  fontSize={"1.2rem"} color={"#fff"} paddingTop={"2%"}>{t('what we do with your data')} </Typography>

            <Typography variant="h5" color={"#fff"} fontWeight={"bold"} textAlign={"left"} marginLeft={"10%"} marginTop={"4%"}> {t('Declaration')}</Typography>

            <Typography variant='h6' style={{ color: "#fff", fontSize: "1.1rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "4%", width: "70%" }}> {t('READ THIS POLICY CAREFULLY BEFORE BROWSING THIS WEBSITE OR USING ANY OF OUR SERVICES. YOUR CONTINUED USE OF THIS WEBSITE AND OUR SERVICES INDICATES THAT YOU HAVE BOTH READ AND AGREE TO THE TERMS OF THIS PRIVACY POLICY. YOU CANNOT USE THIS WEBSITE OR OUR SERVICES IF YOU DO NOT ACCEPT THIS POLICY. ALL SECTIONS OF THIS POLICY ARE APPLICABLE TO USERS UNLESS THE SECTION EXPRESSLY STATES OTHERWISE.')}
            </Typography>

            <Typography variant="h4" sx={{ color: "#fff", fontFamily: "Poppins", fontSize: "1.6rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "4%" }} >{t('Clause 1: Privacy Policy')} </Typography>

            <ul className="indent-heading" style={{ color: "#fff", textAlign: "left", marginLeft: "11%", width: "70%" }}>
              <li>{t('1.1 For the purposes of this section, Personal Information will be understood in accordance with the definition provided in the Protection of Personal Information Act 4 of 2013 (\"the Act\") and in accordance with the definition of Personal Data in Article 4(1) of the General Data Protection Regulation GDPR (EU) 2016/679. We also subscribe to the principles for electronically collecting personal information outlined in the Act, and the further legislation referred to therein. We endeavour to ensure the quality, accuracy and confidentiality of Personal Information in our possession.')}
              </li>

              <li>{t('1.2 In adopting this Privacy Policy, we wish to balance our legitimate business interests and your reasonable expectation of privacy. Accordingly, we will take all reasonable steps to prevent unauthorised access to, or disclosure of your Personal Information. However, it is impossible to guarantee that your Personal Information shall be 100% secure.')}
              </li>

              <li>{t('1.3 In utilising our website, using our services or otherwise if your information is submitted to us through a lead generation services, users may be asked to provide the following information (Personal Information); ')}
              </li>

                <li>
                  <ul className="indent">
                    <li> {t('1.3.1 First Name')} </li>
                    <li> {t('1.3.2 Email')} </li>
                  </ul>
                </li>

              <li>{t('1.4 We will attempt to limit the types of Personal Information we process to only that to which you consent to (for example, in the context of online registration, newsletters, message boards, surveys, polls, professional announcements, SMS, lead generation, MMS and other mobile services), but, to the extent necessary, your agreement to these this Privacy Policy constitutes your consent as contemplated in section 69 of the Act Act and/or Article 7 of the GDPR (whichever is applicable). Where necessary, we will seek to obtain your specific consent in future instances should we deem same required by law and where your consent herein might not be lawfully sufficient.')}
              </li>

              <li>{t('1.5 We will not collect, use or disclose sensitive information (such as information about racial or ethnic origins or political or religious beliefs, where relevant) except with your specific consent or in the circumstances permitted by law.')}
              </li>

              <li>{t('1.6 By agreeing to the terms contained in this Privacy Policy, you consent to the use of your Personal Information in relation to;')}

                <ul className="indent">
                  <li>{t('1.6.1 The provision and performance of our services to you')}</li>
                  <li>{t('1.6.2 The provision of marketing related services to you by us')}</li>
                  <li>{('1.6.3 Responding to any queries or requests you may have')}</li>
                  <li>{t('1.6.4 Developing a more direct and substantial relationship with users')}</li>
                </ul>
              </li>

              <li>{t('1.7 Although absolute security cannot be guaranteed on the internet, we have in place up-to-date, reasonable technical and organisational security measures to protect your Personal Information against accidental or intentional manipulation, loss, misuse, destruction or against unauthorised disclosure or access to the information we process online.')}
              </li>

              <li>{t('1.8 While we cannot ensure or warrant the security of any Personal Information you provide us, we will continue to maintain and improve these security measures over time in line with legal and technological developments.')}
              </li>

              <li>{t('1.9 We store your Personal Information directly, or alternatively, store your Personal Information on, and transfer your Personal Information to, a central database. If the location of the central database is located in a country that does not have substantially similar laws which provide for the protection of Personal Information, we will take the necessary steps to ensure that your Personal Information is adequately protected in that jurisdiction by means of ensuring that standard data protection clauses are enforced as envisaged by the GDPR.')}
              </li>

              <li>{('1.10 Your information will not be stored for longer than is necessary for the purposes described in this Privacy Policy or as required by applicable legislation.')}
              </li>

              <li>{t('1.11 The Personal Information we collect from users shall only be accessed by our employees, representatives and consultants on a need-to-know basis, and subject to reasonable confidentiality obligations binding such persons.')}
              </li>

              <li>{t('1.12 We shall have the right, but shall not be obliged, to monitor or examine any information and materials including any website link that you publish or submit to us for publishing on the Site. You shall be solely responsible for the contents of all material published by yourself.')}
              </li>

              <li>{t('1.13 We constantly review our systems and data to ensure the best possible service to our users. We do not accept any users, or representatives of users, under 18 years of age or who otherwise does not have the relevant capacity to be bound by this Privacy Policy.')}
              </li>

              <li>{t('1.14 We will not sell, share, or rent your Personal Information to any third party or use your e-mail address for unsolicited mail. Any emails sent by us will only be in connection with the provision of our services and/or the marketing thereof.')} 
              </li>
              
            </ul>

            <Typography variant="h4" sx={{ color: "#fff", fontFamily: "Poppins", fontSize: "1.6rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "4%" }}> {t('Clause 2: Your Rights')} </Typography>

            <li className="indent-heading" style={{ color: "#fff", textAlign: "left", marginLeft: "11%", width: "70%" }}> {t('2.1 A user has the right;')}
              <ul className="indent" style={{ color: "#fff" }}>
                <li>{t('2.1.1 of access to their Personal Information stored and processed by us. This may be done by emailing us at the address referred to in clause 7.5.3 below;')}
                </li>

                <li>{t('2.1.2 to rectification of Personal Information that we hold on a user\'s behalf, in instances where such Personal Information is incorrect or incomplete;')}
                </li>

                <li>{t('2.1.3 of erasure of Personal Information ("right to be forgotten") if such information is no longer needed for the original processing purpose, alternatively if a user withdraws their consent and there is no other reason or justification to retain such Personal Information, further alternatively, if a user has objected to such Personal Information being processed and there is no justified reason for the processing of such Personal Information;')}
                </li>

                <li>{t('2.1.4 to restrict/suspend processing of Personal Information to only that which is strictly necessary for us to perform our services to you;')}
                </li>

                <li>{t('2.1.5 if we breach any of the terms of this Privacy Policy, to complain to the requisite data protection authority in their territory;')}
                </li>

                <li>{t('2.1.6 to withdraw their consent at any time, if processing of Personal Information is based on consent;')}
                </li>

                <li>{t('2.1.7 to object to processing of Personal Information, if such processing is based on legitimate interests; and')}</li>

                <li>{t('2.1.8 to object to the processing of Personal Information for direct marketing purposes.')}
                </li>
              </ul>
            </li>

            <Typography variant="h4" sx={{ color: "#fff", fontFamily: "Poppins", fontSize: "1.6rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "4%" }}> 
              {t('Clause 3: Disclosure of Personal Information')} 
            </Typography>

            <ul className="indent-heading" style={{ color: "#fff", textAlign: "left", marginLeft: "11%", width: "70%" }}>
              <li>{t('3.1 We do not disclose your Personal Information to anyone outside Party.')}</li>
            </ul>

            <Typography variant="h4" sx={{ color: "#fff", fontFamily: "Poppins", fontSize: "1.6rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "4%" }}> {t('Clause 4: Log Files')} </Typography>

            <ul className="indent-heading" style={{ color: "#fff", textAlign: "left", marginLeft: "11%", width: "70%" }}>
              <li>
                <p>{t('4.1 When you visit the website, even if you do not create an account, we may collect information, such as your IP address, your browser, the date and length of your visit, and other information concerning your computer\'s operating system and language settings.')}
                </p>

                <p>{t('This information is aggregated and anonymous data and does not identify you specifically. However, you acknowledge that this data may be able to be used to identify you if it is aggregated with other Personal Information that you supply to us. This information is not shared with third parties and is used only within the company on a need-to-know basis. Any individually identifiable information related to this data will never be used in any way different to that stated above, without your explicit permission.')}
                </p>
              </li>
            </ul>

            <Typography variant="h4" sx={{ color: "#fff", fontFamily: "Poppins", fontSize: "1.6rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "4%" }}> {t('Clause 5: Cookies')} </Typography>

            <ul className="indent-heading" style={{ color: "#fff", textAlign: "left", marginLeft: "11%", width: "70%" }}>
              <li>{t('5.1 We do not make use of cookies.')}</li>
            </ul>

            <Typography variant="h4" sx={{ color: "#fff", fontFamily: "Poppins", fontSize: "1.6rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "4%" }}> {t('Clause 6: Links from the website')}</Typography>

            <ul className="indent-heading" style={{ color: "#fff", textAlign: "left", marginLeft: "11%", width: "70%" }}>
              <li>{t('6.1 The services available through the Website, may contain links to other third party websites, including (without limitation) social media platforms, payment gateways appointment scheduling and/or live chat platforms ("Third Party Websites"). If you select a link to any Third Party Website, you may be subject to such Third Party Website\'s terms and conditions and/or other policies, which are not under our control, nor are we responsible therefore.')}
              </li>

              <li>{t('6.2 Hyperlinks to Third Party Websites are provided "as is", and we do not necessarily agree with, edit or sponsor the content on Third Party Websites.')}
              </li>

              <li>{t('6.3 We do not monitor or review the content of any Third Party Website. Opinions expressed or material appearing on such websites are not necessarily shared or endorsed by us and we should not be regarded as the publisher of such opinions or material. Please be aware that we are not responsible for the privacy practices, or content, of other websites, either.')}
              </li>

              <li>{t('6.4 Users should evaluate the security and trustworthiness of any Third Party Website before disclosing any personal information to them. We do not accept any responsibility for any loss or damage in whatever manner, howsoever caused, resulting from your disclosure to third parties of personal information.')}
              </li>
            </ul>

            <Typography variant="h4" sx={{ color: "#fff", fontFamily: "Poppins", fontSize: "1.6rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "4%" }}> {t('Clause 7: Application Of The Electronic Communications And Transactions Act 25 Of 2002 ("Ect Act")')} </Typography>

            <ul className="indent-heading" style={{ color: "#fff", textAlign: "left", marginLeft: "11%", width: "70%" }}>
              <li>{t('7.1 Data Messages (as defined in the ECT Act) will be deemed to have been received by us if and when we respond to the Data Messages.')}
              </li>

              <li>{t('7.2 Data Messages sent by us to a user will be deemed to have been received by such user in terms of the provisions specified in section 23(b) of the ECT Act.')}
              </li>

              <li>{t('7.3 Users acknowledge that electronic signatures, encryption and/or authentication are not required for valid electronic communications between us and users.')}
              </li>

              <li>{t('7.4 Information to be provided in terms of section 43(1) of the ECT Act;')}
                <ul className="indent">
                  <li>{t('7.4.1 Users warrant that Data Messages sent to us from any electronic device, used by such user, from time to time or owned by such user, were sent and or authorised by such user, personally.')}
                  </li>

                  <li>{t('7.4.2 This Website is owned and operated by Pyrrho Tech Pty Ltd.')}</li>
                </ul>
              </li>

              <li>{t('7.5 Address for service of legal documents: 4 Katoomba Street, Kensignton, Johannesburg.')}</li>

              <ul className="indent" style={{ color: "#fff" }}>
                <li>{t('7.5.1 Website - located at pyrrhotech.com')}</li>
                <li>{t('7.5.2 Email address- info@pyrrhotech.com')}</li>
              </ul>
            </ul>

            <Typography variant="h4" sx={{ color: "#fff", fontFamily: "Poppins", fontSize: "1.6rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "4%" }}> {t('Clause 8: Lodging of Complaints')} </Typography>

            <ul className="indent-heading" style={{ color: "#fff", textAlign: "left", marginLeft: "11%", width: "70%" }}>
              <li>{t('8.1 We only process your Personal Information in compliance with this Privacy Policy and in accordance with the relevant data protection laws. If, however you wish to raise a complaint regarding the processing of your Personal Information or are unsatisfied with how we have handled your Personal Information, you have the right to lodge a complaint with the supervisory authority in your country.')}
              </li>
            </ul>
          </Grid >

          <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ marginTop: "5%", paddingBottom: "15%" }}>
            <Typography id="terms" className="faqText" fontSize={"1.5rem"} paddingTop={"5%"} fontFamily={"Roboto Condensed"} fontWeight={"bold"}> {t('TERMS')} </Typography>
            <Typography fontSize={"1.2rem"} color={"#fff"} paddingTop={"2%"}> {t('for using the website')} </Typography>

            <Typography variant="body1" className="faqText" textAlign={"left"} marginLeft={"10%"} marginTop={"4%"}> {t('Last updated: July 28, 2022')} </Typography>

            <Typography variant='h6' className="faqText" style={{  fontSize: "1.1rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "4%", width: "70%" }}> {t('Please read these terms and conditions carefully before using Our Service.')}
            </Typography>

            <Typography variant="h5" className="faqText" sx={{ fontWeight: "bold", textAlign: "left", fontFamily: "Roboto Condensed", marginLeft: "10%", marginTop: "2%" }}> 
              {t('Interpretation and Definitions')}
            </Typography>

            <Typography variant="h4" className="faqText" sx={{ textAlign: "left", fontSize: "1.8rem", fontFamily: "Poppins", marginLeft: "10%", marginTop: "2%" }}> {t('Interpretation')}</Typography>

            <Typography variant='h6' className="faqText" style={{  fontSize: "1.1rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "1%", width: "80%" }}> {t('The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.')}
            </Typography>

            <Typography variant="h4" className="faqText" sx={{ textAlign: "left", fontSize: "1.8rem", fontFamily: "Poppins", marginLeft: "10%", marginTop: "2%" }}> {t('Definition')} </Typography>

            <Typography variant='h6' className="faqText" style={{fontSize: "1.1rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "4%", width: "70%" }}> {t('For the purposes of these Terms and Conditions;')}
            </Typography>

            <ul  className="faqText indent-heading" style={{ textAlign: "left", marginLeft: "11%", width: "70%" }}>
              <li> <b>{t('Affiliate')}</b> {t('means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.')}</li>

              <li> <b>{t('Country')}</b> {t("refers to- South Africa")}</li>

              <li> <b>{t('Company')}</b>  {t('(referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Pyrrho Tech, 4 Katoomba Street.')} </li>

              <li> <b>{t('Device')}</b> {t('means any device that can access the Service such as a computer, a cellphone or a digital tablet.')} </li>

              <li> <b>{t('Service')}</b>  {t('refers to the Website.')} </li>

              <li> <b>{t('Terms and Conditions')}</b>  {t('(also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service. This Terms and Conditions agreement has been created with the help of the')} <a href="https://www.termsfeed.com/terms-conditions-generator/" target="_blank"> {t('TermsFeed Terms and Conditions Generator')}</a></li>

              <li> <b>{t('Third-party Social Media Service')}</b>  {t('means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.')} </li>

              <li> <b>{t('Website')}</b> {t('refers to Pyrrho Tech, accessible from')}<a href="https://pyrrhotech.com "> {t('https://pyrrhotech.com')} </a></li>

              <li> <b>{t('You')}</b> {t('means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.')} </li>
            </ul>

            <Typography variant="h5" className="faqText" sx={{ fontWeight: "bold", textAlign: "left", fontFamily: "Roboto Condensed", marginLeft: "10%", marginTop: "4%" }}> {t('Acknowledgment')}</Typography>

            <Typography variant='h6' className="faqText" style={{ fontSize: "1rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "2%", width: "75%" }}> {t('These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.')}
            </Typography>

            <Typography variant='h6' className="faqText" style={{ fontSize: "1rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "1%", width: "75%" }}> {t('Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.')}
            </Typography>

            <Typography variant='h6' className="faqText" style={{ fontSize: "1rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "1%", width: "75%" }}> {t('By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.')}
            </Typography>

            <Typography variant='h6' className="faqText" style={{  fontSize: "1rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "1%", width: "75%" }}> {t('You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.')}
            </Typography>

            <Typography variant='h6' className="faqText" style={{ fontSize: "1rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "1%", width: "75%" }}> {t('Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.')}
            </Typography>

            <Typography variant="h5" className="faqText" sx={{ fontWeight: "bold", textAlign: "left", fontFamily: "Roboto Condensed", marginLeft: "10%", marginTop: "4%" }}> {t('Links to Other Websites')} </Typography>

            <Typography variant='h6' className="faqText" style={{ fontSize: "1rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "2%", width: "75%" }}>{t('Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.')}
            </Typography>

            <Typography variant='h6' className="faqText" style={{ fontSize: "1rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "1%", width: "75%" }}> {t('The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.')}
            </Typography>

            <Typography variant='h6' className="faqText" style={{  fontSize: "1rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "1%", width: "75%" }}> {t('We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.')}
            </Typography>

            <Typography variant="h5" className="faqText" sx={{ fontWeight: "bold", textAlign: "left", fontFamily: "Roboto Condensed", marginLeft: "10%", marginTop: "4%" }}> {t('Termination')} </Typography>

            <Typography variant='h6' className="faqText" style={{ fontSize: "1rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "2%", width: "75%" }}> {t('We may terminate or suspend Your access immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions.')}
            </Typography>

            <Typography variant='h6' className="faqText" style={{  fontSize: "1rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "1%", width: "75%" }}> {t('Upon termination, Your right to use the Service will cease immediately.')}
            </Typography>

            <Typography variant="h5" className="faqText" sx={{  fontWeight: "bold", textAlign: "left", fontFamily: "Roboto Condensed", marginLeft: "10%", marginTop: "4%" }}> {t('Limitation of Liability')} </Typography>

            <Typography variant='h6' className="faqText" style={{ fontSize: "1rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "2%", width: "75%" }}> {t('Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the Service or 100 USD if You haven\'t purchased anything through the Service.')}
            </Typography>

            <Typography variant='h6' className="faqText" style={{ fontSize: "1rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "1%", width: "75%" }}> {t('To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.')}
            </Typography>

            <Typography variant='h6' className="faqText" style={{ fontSize: "1rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "1%", width: "75%" }}> {t('Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party\'s liability will be limited to the greatest extent permitted by law.')}
            </Typography>

            <Typography variant='h6' className="faqText" style={{ fontSize: "1.3rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "1%", width: "75%" }}> {t('"AS IS" and "AS AVAILABLE" Disclaimer')}
            </Typography>

            <Typography variant='h6' className="faqText" style={{ fontSize: "1rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "1%", width: "75%" }}> {t('The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.')}
            </Typography>

            <Typography variant='h6' className="faqText" style={{ fontSize: "1rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "1%", width: "75%" }}> {t('Without limiting the foregoing, neither the Company nor any of the company\'s provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.')}
            </Typography>


            <Typography variant='h6' className="faqText" style={{ fontSize: "1rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "1%", width: "75%" }}> {t('Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.')}
            </Typography>


            <Typography variant="h5" className="faqText" sx={{ fontWeight: "bold", textAlign: "left", fontFamily: "Roboto Condensed", marginLeft: "10%", marginTop: "4%" }}> {t('Governing Law')} </Typography>

            <Typography variant='h6' className="faqText" style={{ fontSize: "1rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "2%", width: "75%" }}> {t('The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws.')}
            </Typography>

            <Typography variant="h5" className="faqText" sx={{ fontWeight: "bold", textAlign: "left", fontFamily: "Roboto Condensed", marginLeft: "10%", marginTop: "4%" }}> {t('Disputes Resolution')}  </Typography>

            <Typography variant='h6' className="faqText" style={{ fontSize: "1rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "2%", width: "75%" }}> {t('If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.')}
            </Typography>

            <Typography variant="h5" className="faqText" sx={{ fontWeight: "bold", textAlign: "left", fontFamily: "Roboto Condensed", marginLeft: "10%", marginTop: "4%" }}> {t('For European Union (EU) Users')} </Typography>

            <Typography variant='h6' className="faqText" style={{ fontSize: "1rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "2%", width: "75%" }}> {t('If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.')}
            </Typography>

            <Typography variant="h5" className="faqText" sx={{ fontWeight: "bold", textAlign: "left", fontFamily: "Roboto Condensed", marginLeft: "10%", marginTop: "4%" }}> {t('United States Legal Compliance')} </Typography>

            <Typography variant='h6' className="faqText" style={{ fontSize: "1rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "2%", width: "75%" }}> {t('You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a "terrorist supporting" country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.')}
            </Typography>

            <Typography variant="h5" className="faqText" sx={{ fontWeight: "bold", textAlign: "left", fontFamily: "Roboto Condensed", marginLeft: "10%", marginTop: "4%" }}> {t('Severability and Waiver')} </Typography>

            <Typography variant="h4" className="faqText" sx={{ textAlign: "left", fontSize: "2rem", fontFamily: "Poppins", marginLeft: "10%", marginTop: "2%" }}> {t("Severability")}
            </Typography>

            <Typography variant='h6' className="faqText" style={{ fontSize: "1rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "2%", width: "75%" }}> {t('If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.')}
            </Typography>

            <Typography variant="h4" className="faqText" sx={{ textAlign: "left", fontSize: "2rem", fontFamily: "Poppins", marginLeft: "10%", marginTop: "2%" }}> {t('Waiver')}
            </Typography>

            <Typography variant='h6' className="faqText" style={{ fontSize: "1rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "2%", width: "75%" }}> {t('Except as provided herein, the failure to exercise a right or to require performance of an obligation under these Terms shall not effect a party\'s ability to exercise such right or require such performance at any time thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach.')}
            </Typography>


            <Typography variant="h5" className="faqText" sx={{ fontWeight: "bold", textAlign: "left", fontFamily: "Roboto Condensed", marginLeft: "10%", marginTop: "2%" }}> {t('Translation Interpretation')}</Typography>

            <Typography variant='h6' className="faqText" style={{ fontSize: "1rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "2%", width: "75%" }}> {t('These Terms and Conditions may have been translated if We have made them available to You on our Service. You agree that the original English text shall prevail in the case of a dispute.')}
            </Typography>

            <Typography variant="h5" className="faqText" sx={{ fontWeight: "bold", textAlign: "left", fontFamily: "Roboto Condensed", marginLeft: "10%", marginTop: "2%" }}>{t('Changes to These Terms and Conditions')} </Typography>

            <Typography variant='h6' className="faqText" style={{ fontSize: "1rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "2%", width: "75%" }}> {t('We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at least 30 days\' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.')}
            </Typography>

            <Typography variant='h6' className="faqText" style={{ fontSize: "1rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "2%", width: "75%" }}> {t('By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.')}
            </Typography>

            <Typography variant="h5" className="faqText" sx={{ fontWeight: "bold", textAlign: "left", fontFamily: "Roboto Condensed", marginLeft: "10%", marginTop: "2%" }}> {t('Contact Us')} </Typography>

            <Typography variant='h6' className="faqText" style={{  fontSize: "1rem", fontWeight: 400, textAlign: "left", marginLeft: "10%", marginTop: "2%", width: "75%" }}> {t('If you have any questions about these Terms and Conditions, You can contact us:')}
            </Typography>

            <ul className="faqText indent-headingla" style={{ textAlign: "left", marginLeft: "11%", width: "70%" }}>
              <li> {t('By email:')} <a className="faqLinks" href="mailto:pyrrho.tech@pyrrhotech.com">pyrrho.tech@pyrrhotech.com</a> </li>

              <li> {t('By visiting this page on our website:')} <a className="faqLinks" href="">https://www.pyrrhotech.com/contact-us</a> </li>
            </ul>
          </Grid >
        </>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
          <div style={{ marginTop: '25vh' }}><CircleLoader color="#c3978f" size={100} /></div>

        </div>
      )
      }
    </>


  );
}
