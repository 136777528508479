import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import NavBar from './navbar/Navbar';
import { Footer } from './footer/Footer';

export function Layout() {
  return (
    <>
      <NavBar />
      <div >
        <div style={{ marginTop: '12vh', minHeight: '85vh' }}>
          <Outlet />
        </div>
      </div>
      <Footer />
    </>
  );
}