
import "./Footer.css";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { NavLink } from "react-router-dom";
import footerImg from '../../../src/assets/img/logo-black.png'
import { Box } from "@mui/material";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { HashLink as Link } from 'react-router-hash-link';
import { useTranslation } from "react-i18next";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#000',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export function Footer() {
    const { t } = useTranslation();

    return (
        <>
            <footer style={{ color: "#fff", width: '100%', minHeight: '17vh', backgroundColor: "#000", marginTop: "24px", paddingBottom: "60px" }}>

                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2} sx={{ marginRight: "60px" }}>

                        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                            <Item>
                                <Typography variant="h2" sx={{ fontSize: "30px", color: "#fff", margin: "10px 0px", marginBottom: "30px", fontFamily: "Poppins" }}> {t('Our Services')} </Typography>

                                <Typography component={Link} to="/home#support" variant="h3" sx={{
                                    display: "block", fontSize: "17px", color: "#fff", marginBottom: "1rem", textDecoration: "none", '&:hover': {
                                        color: "#009688",
                                        transition: "0.20"
                                    }
                                }}> {t('Support')} </Typography>

                                <Typography component={Link} to="/home#custom" variant="h3" sx={{
                                    display: "block", fontSize: "17px", color: "#fff", marginBottom: "1rem", textDecoration: "none", '&:hover': {
                                        color: "#009688",
                                        transition: "0.20"
                                    }
                                }}> {t('Custom Set-up')} </Typography>

                                <Typography component={Link} to="/home#soft" variant="h3" sx={{
                                    display: "block", fontSize: "17px", color: "#fff", marginBottom: "1rem", textDecoration: "none", '&:hover': {
                                        color: "#009688",
                                        transition: "0.20"
                                    }
                                }}> {t('Custom Software')} </Typography>

                                <Typography component={Link} to="/home#infra" variant="h3" sx={{
                                    display: "block", fontSize: "17px", color: "#fff", marginBottom: "1rem", textDecoration: "none", '&:hover': {
                                        color: "#009688",
                                        transition: "0.20"
                                    }
                                }}> {t('Infrastructure and Management')} </Typography>
                            </Item>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                            <Item>
                                <Typography variant="h2" sx={{ fontSize: "30px", color: "#fff", margin: "10px 0px", marginBottom: "30px", fontFamily: "Poppins" }}>{t('Support Plans')}</Typography>

                                <Typography component={Link} to="/home#basic" variant="h3" sx={{
                                    fdisplay: "block", fontSize: "17px", color: "#fff", marginBottom: "1rem", textDecoration: "none", '&:hover': {
                                        color: "#009688",
                                        transition: "0.20"
                                    }
                                }}> {t('Basic Support Plan')}  </Typography>

                                <Typography component={Link} to="/home#specialised" variant="h3" sx={{
                                    display: "block", fontSize: "17px", color: "#fff", marginBottom: "1rem", marginTop: "1rem", textDecoration: "none", '&:hover': {
                                        color: "#009688",
                                        transition: "0.20"
                                    }
                                }}> {t('Specialised Support Plan')} </Typography>

                                <Typography component={Link} to="/home#intermediate" variant="h3" sx={{
                                    display: "block", fontSize: "17px", color: "#fff", marginBottom: "1rem", textDecoration: "none", '&:hover': {
                                        color: "#009688",
                                        transition: "0.20"
                                    }
                                }}> {t('Intermediate Support Plan')}</Typography>

                                <Typography component={Link} to="/home#professional" variant="h3" sx={{
                                    display: "block", fontSize: "17px", color: "#fff", marginBottom: "1rem", textDecoration: "none", '&:hover': {
                                        color: "#009688",
                                        transition: "0.20"
                                    }
                                }}> {t('Professional Support Plan')} </Typography>


                            </Item>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                            <Item>
                                <Typography variant="h2" sx={{ fontSize: "30px", color: "#fff", margin: "10px 0px", marginBottom: "30px", fontFamily: "Poppins" }}> {t('Policies')} </Typography>

                                <Typography variant="h3" component={Link} to="/faq#faq" sx={{
                                    display: "block", fontSize: "17px", color: "#fff", marginBottom: "0.2rem", textDecoration: "none", '&:hover': {
                                        color: "#009688",
                                        transition: "0.20"
                                    }
                                }}> {t('FAQ')} </Typography>
                                <br />

                                <Typography variant="h3" component={Link} to="/faq#terms" sx={{
                                    display: "block", fontSize: "17px", color: "#fff", marginBottom: "0.2rem", textDecoration: "none", '&:hover': {
                                        color: "#009688",
                                        transition: "0.20"
                                    }
                                }}> {t('Terms')} </Typography>
                                <br />
                                

                                <Typography component={Link} to="/faq#privacy" variant="h3" sx={{
                                    display: "block", fontSize: "17px", color: "#fff", marginBottom: "0.2rem", textDecoration: "none", '&:hover': {
                                        color: "#009688",
                                        transition: "0.20"
                                    }
                                }}> {t('Privacy')} </Typography>
                                <br />
                            </Item>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={3} xl={3} className="foo">
                            <Item>
                                <Typography variant="h2" sx={{ fontSize: "30px", color: "#fff", margin: "10px 0px", marginBottom: "30px", fontFamily: "Poppins" }}>{t('Info')}</Typography>

                                <Typography variant="h3"> <a href="mailto:finance@pyrrhotech.com">{t('Email')}</a> </Typography>
                                <br />
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
            </footer>
        </>
    );
}