import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


// import awsExports from "./aws-exports";
import "./index.css";
import { Amplify } from 'aws-amplify';

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ThemeContextProvider } from './components/ThemeContext';

// Amplify.configure(awsExports);


Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  environment: process.env.REACT_APP_SENTRY_ENV,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading....</div>}>
      <ThemeContextProvider>
        <App />
      </ThemeContextProvider>
    </Suspense>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
