import { Authenticator } from '@aws-amplify/ui-react';

// import { Login } from './components/auth/Login';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './App.css';
import { Home } from './pages/home/home';
import { RequireAuth } from './components/auth/RequireAuth';
import { Layout } from './layout/Layout';
import { AppRoute, CustomRoutes } from './routes'
import { NotFoundPage } from './pages/errors/400';

import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import React, { useState } from "react";
import { IconButton, Box, Drawer } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import { LanguageSelector } from "./components/LanguageSelector";
import Switch from './components/Switch';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';

function MyRoutes() {

  return (
    < >
      <ReactNotifications />

      <BrowserRouter>
        <Routes>

          <Route path="/" element={<Layout />}>

            {/* Default index page */}
            <Route index element={<Home />} />

            {/* These get loaded from the routes. */}
            {CustomRoutes.map((cRo: AppRoute) => {
            const navigation =  cRo.Anchor.length>0 ? cRo.Navigation + cRo.Anchor : cRo.Navigation

              return (
                <Route
                  key={cRo.DisplayName}
                  path={navigation}
                  element={cRo.Protected ?
                    <RequireAuth>
                      <cRo.Component />
                    </RequireAuth> :
                    <cRo.Component />}
                />
              )
            })}

            {/* {SettingRoutes.map((setting: AppRoute) => (
              <Route
                key={setting.DisplayName}
                path={setting.Navigation}
                element={setting.Protected ?
                  <RequireAuth>
                    <setting.Component />
                  </RequireAuth> :
                  <setting.Component />}
              />
            ))} */}
            {/* <Route path="/login" element={<Login />} /> */}
            {/* Catches any routes not selected */}
            <Route path='*' element={<NotFoundPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

function App() {
  const [openMenu, setOpenMenu] = useState(false);

  const toggleMenu = () => {
    setOpenMenu((prev) => !prev);
  };

  const closeMenu =() => {
    setOpenMenu(false);
  }

  return (
    <Authenticator.Provider >
      <MyRoutes />
      <GearMenuWrapper>
        <IconButton onClick={toggleMenu} sx={{ color: '#174F7A' }}>
          <SettingsIcon fontSize='large' />
        </IconButton>
      </GearMenuWrapper>

      <Drawer
        anchor="bottom"
        open={openMenu}
        onClose={toggleMenu}
        sx={{
          ".MuiDrawer-paper": {
            '&::-webkit-scrollbar': {
              display: 'none'
            },
            width: {xs: "40%", sm: "20%", lg: "10%"},
            height: "25%",
            display: "flex",
            justifySelf: "flex-end",
            background: "#ffffff",
            borderRadius: "5px",
            animation: openMenu ? "slideUp 0.5s ease-in-out" : "",
            marginBottom: "2vh",
            marginRight: "2vw"
          }
        }}
      >
        <MenuContent>
          <Box sx={{ display: "flex", justifyContent: "flex-end", margin: "2vh 1vw"}}>
            <CloseIcon fontSize="large" onClick={closeMenu} sx={{ display: "flex", textAlign: "end", "&: hover": { color: "red", cursor: "pointer"}}} />
          </Box>
          

          <Box sx={{ display: "flex", justifyContent: "center", marginY: "1vh"}}>
            <LanguageSelector />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "center", marginY: "1.5vh"}}>
            <Switch />
          </Box>

        </MenuContent>
      </Drawer>
    </Authenticator.Provider>
  );
}

const GearMenuWrapper = styled.div`
  position: fixed;
  bottom: 2vh;
  right: 2vw;
  z-index: 1000;
`;

// Slide up animation for the menu content
const MenuContent = styled.div`
  width: 5vw
  height: 2vh
  padding: 2vh 5vw;

  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }

  .menu-section {
    margin-bottom: 2vh;
    animation: slideUp 0.5s ease-in-out;
  }
`;
export default App;