// LanguageSelector.tsx
import React, { useState } from "react";
import i18n from '../i18n/index';
import { IconButton } from "@mui/material";
import TranslateIcon from '@mui/icons-material/Translate';
import MenuItem from '@mui/material/MenuItem';
import { Select, FormControl, InputLabel, Box, useMediaQuery, useTheme } from '@mui/material';
import styled from 'styled-components';

export const LanguageSelector = () => {
    const [selectedLanguage, setSelectedLanguage] = useState("en");
    const [open, setOpen] = useState(false);

    const theme = useTheme();
    const isTabletOrLarger = useMediaQuery(theme.breakpoints.up('sm'));

    const chooseLanguage = (e) => {
        e.preventDefault();
        i18n.changeLanguage(e.target.value);   
        setSelectedLanguage(e.target.value);
        localStorage.setItem("lang", e.target.value);
    }

    const handleClick = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <StyledLanguageSelector>
            <Box sx={{ border: "2px solid #009688", borderRadius: "0.3vw", display: "flex", flexDirection: "row", width: {xs: "22vw", sm: "10vw", lg: "8vw"}, marginX: { xs: "8vw", sm: "2vw"}, justifyContent: "center", alignItems: "center" }}>
                <IconButton onClick={chooseLanguage} sx={{ padding: {xs: "0.1vh auto", sm: "0.3vw"}, marginX: {xs: "6vw", sm: 0} }}>
                    <TranslateIcon onClick={handleClick} sx={{ color: '#009688' }} />
                </IconButton>

                <Select 
                    className={`languageSelector ${isMobile ? 'hiddenValue' : ''}`}
                    value={selectedLanguage} 
                    onChange={(event) => {
                      chooseLanguage(event);
                      handleClose();
                    }}
                    open={open}
                    onClose={handleClose}
                    variant="outlined" 
                    onOpen={handleClick}
                    sx={{
                        display: isTabletOrLarger ? 'flex-end' : 'initial', 
                        boxShadow: 'none', 
                        '.MuiOutlinedInput-notchedOutline': { 
                            border: 0 
                        }, 
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 0,
                        },
                        '&.MuiOutlineInput-root:hover': {
                            border: '0px solid transparent'
                        }
                    }}>
                      <StyledMenuItem value="en"
                        sx={{ 
                            "&.Mui-selected": {
                                backgroundColor: "#004d40",
                                color: "#fff"
                            },
                            "&: hover": {
                                backgroundColor: "#009688",
                                color: "#fff",
                                fontWeight: 600
                            },
                            "&.Mui-selected:hover": {
                                backgroundColor: "#009688",
                                color: "#fff",
                                fontWeight: 600
                            }
                        }}
                      > English </StyledMenuItem>

                    <StyledMenuItem value="af"
                      sx={{ 
                        "&.Mui-selected": {
                            backgroundColor: "#004d40",
                            color: "#fff"
                        },
                        "&: hover": {
                            backgroundColor: "#009688",
                            color: "#fff",
                            fontWeight: 600
                        },
                        "&.Mui-selected:hover": {
                            backgroundColor: "#009688",
                            color: "#fff",
                            fontWeight: 600
                        }
                    }}> Afrikaans </StyledMenuItem>
                    
                    <StyledMenuItem value="zu"
                      sx={{ 
                        "&.Mui-selected": {
                            backgroundColor: "#004d40",
                            color: "#fff"
                        },
                        "&: hover": {
                            backgroundColor: "#009688",
                            color: "#fff",
                            fontWeight: 600
                        },
                        "&.Mui-selected:hover": {
                            backgroundColor: "#009688",
                            color: "#fff",
                            fontWeight: 600
                        }
                    }}> IsiZulu </StyledMenuItem>

                    <StyledMenuItem value="st"
                      sx={{ 
                        "&.Mui-selected": {
                            backgroundColor: "#004d40",
                            color: "#fff"
                        },
                        "&: hover": {
                            backgroundColor: "#009688",
                            color: "#fff",
                            fontWeight: 600
                        },
                        "&.Mui-selected:hover": {
                            backgroundColor: "#009688",
                            color: "#fff",
                            fontWeight: 600
                        }
                    }}> Sesotho </StyledMenuItem>
                </Select>
            </Box>
        </StyledLanguageSelector>
    );
};

// Styled components for animation
const StyledLanguageSelector = styled.div`
  .languageSelector {
    animation: slideUpOptions 0.5s ease-in-out;
  }

  @keyframes slideUpOptions {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  animation: slideUp 0.3s ease-in-out;

  &:hover, &.Mui-selected:hover {
    background-color: #009688;
    color: #fff;
    font-weight: 600;
  }

  @keyframes slideUp {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

export default LanguageSelector;
