import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { AppRoute, CustomRoutes } from '../../routes';
import companyLogo from '../../assets/img/pyrro-logo-black.png';
import '../../layout/navbar/Navbar.css';
import { HashLink as Link } from 'react-router-hash-link';
import Stack from '@mui/material/Stack';
import { Grid } from '@mui/material';
import LanguageSelector from '../../components/LanguageSelector';
import { useThemeContext } from '../../components/ThemeContext';
import Switch from '../../components/Switch';
import companyLogoLight from '../../assets/img/pyrro-logo-black.png'; // Light mode logo
import companyLogoDark from '../../assets/img/pyrro-logo-white.png';  // Dark mode logo


function NavBar() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const { route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);

  const navigate = useNavigate();

  function logOut() {
    signOut();
    navigate('/login');
  }
  const { currentTheme, toggleTheme } = useThemeContext(); 

  const companyLogo = currentTheme === 'dark' ? companyLogoDark : companyLogoLight;
  const hamburgerColor = currentTheme === 'dark' ? '#fff' : '#000';

  return (
    
    <AppBar position="fixed" className="navbar" sx={{ boxShadow: "none"}}>
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex', lg: 'flex', xl: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <IconButton aria-label='logo' sx={{
             display: "flex",
             justifyContent: {xs: "center", md: "flex-start"}
            }}>
              <img className="companyLogo" src={companyLogo} alt="Company Logo" />
            </IconButton>
          </Typography>
          


          <Grid container sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>

            <Grid sx={{display: "flex", margin: "12px", padding: "0px"}}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
                sx={{padding: "0px", margin: "0px", float: "none", color: "#000"}}
              >
                <MenuIcon style={{ color: hamburgerColor}} />
              </IconButton>

              <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClick={handleCloseNavMenu}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },

              }}
            >

                {CustomRoutes.map((cRo: AppRoute) => {
                  const navigation = cRo.Anchor.length > 0 ? cRo.Navigation + cRo.Anchor : cRo.Navigation
                 
                  return cRo.NavBarVisibile ?
                    <li key={cRo.DisplayName} style={{

                    }}>
                      <Typography className='sa' component={Link} to={navigation} variant="h3" sx={{
                        display: "flex",
                        flexDirection: "column",
                        color: "#000",
                        fontSize: "1.1rem",
                        margin: "0.6rem",
                        textDecoration: "none",
                        textTransform: "uppercase",
                        fontFamily: "Roboto Condensed",
                        fontWeight: "600",
                        listStyleType: "none",
                        paddingLeft: 0
                      }}> {cRo.DisplayName} </Typography>
                    </li>
                    :
                    null
                })}
            </Menu>

            <Grid sx={{display: "flex", justifyContent: "center", margin: "auto", alignItems: "center"}}>
              <Typography
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                display: "flex",
                justifyContent: 'center',
                alignItems: "center"
              }}>
                <IconButton aria-label='logo' sx={{ 
                  padding: "0px 0px", margin: " auto auto", verticalAlign: "center", display: "flex", alignItems: "center", position: "absolute", left: "50%", right: "50%"  
                }}>
                  <img className="companyLogo" src={companyLogo} />
                </IconButton>
            </Typography>
            </Grid>

            </Grid>
          </Grid>


          <Box>
         
          </Box>
          

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', lg: 'flex', xl: 'flex' }, justifyContent: "flex-end" }}>
              {CustomRoutes.map((cRo: AppRoute) => {
                const navigation = cRo.Anchor.length > 0 ? cRo.Navigation + cRo.Anchor : cRo.Navigation
                
                return cRo.NavBarVisibile ?
                  <MenuItem key={cRo.DisplayName}>
                    <Typography className="navLinks" component={Link} to={navigation} variant="h3" sx={{
                      margin: "0.5vh 1vh",
                      display: "block",
                      fontSize: "1.25rem",
                      fontWeight: "700",
                      marginRight: "1.04vw",
                      textDecoration: "none",
                      textTransform: "uppercase",
                      fontFamily: "Roboto Condensed"
                    }}> {cRo.DisplayName} </Typography>
                  </MenuItem>
                  :
                  null
              })}
          
          </Box>

          {/* <Switch />
          <LanguageSelector /> */}

          {/* <Box sx={{ flexGrow: 0 }}>

            {route !== 'authenticated' ? (
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                <Button
                  key={"Login"}
                  onClick={() => navigate('/login')}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                  style={{fontFamily: 'Inter',
                  fontSize: '14px'}}
                >
                  Login
                </Button>

              </Box>
            ) : (
              <>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt="Test User" src="/static/images/avatar/2.jpg" />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {SettingRoutes.map((setting: AppRoute) => (
                    <MenuItem key={setting.DisplayName} onClick={() => navigate(setting.Navigation)}>
                      <Typography textAlign="center">{setting.DisplayName}</Typography>
                    </MenuItem>
                  ))}
                  <MenuItem key={"logout"} onClick={() => logOut()}>
                    <Typography textAlign="center">Logout</Typography>
                  </MenuItem>
                </Menu>
              </>
            )}

          </Box> */}
        </Toolbar>
    </AppBar>
  );
}
export default NavBar;